import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticStyle:{"box-shadow":"none"}},[_c(VTabs,{attrs:{"bg-color":"transparent"}},[_c(VTab,{attrs:{"value":"one"},on:{"click":function($event){_vm.tab = 'one'}}},[_vm._v(_vm._s(_vm.$t('dashboard.speed')))])],1),_c(VCardText,[_c(VWindow,{staticStyle:{"overflow":"visible"},attrs:{"value":_vm.tab}},[_c(VWindowItem,{attrs:{"value":"one"}},[_c(VRow,[_c('SpeedTab')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }