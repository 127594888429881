import { mapCurrentStateService } from '@/business/mapCurrentStateService'
import i18n from '@/i18n'
import { filterRulesMapped } from '@/tools/filterRules'
import { CellType } from '@colven/common-domain-lib/lib'
import efficiencyConstants  from '@/constants/efficiency.constants'
import { mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import { configurationService } from '@/business/configurationService'


export default {
    name: 'SpeedTab',
    components: {
     
    },
    props: {
    },
    data: () => ({
      tab: null,
      tableFilterRules: {
        timestamp: filterRulesMapped.TEXT_FIELD,
        thingName: filterRulesMapped.TEXT_FIELD,
        trackTimestamp: filterRulesMapped.TEXT_FIELD,
        harvestFront: filterRulesMapped.TEXT_FIELD,
        driveUnit: filterRulesMapped.TEXT_FIELD,
        referenceSpeed:filterRulesMapped.TEXT_FIELD,
        actualSpeed: filterRulesMapped.TEXT_FIELD,
        tonsPerHour: filterRulesMapped.TEXT_FIELD,
        actualTonsPerHour: filterRulesMapped.TEXT_FIELD,
        driver: filterRulesMapped.TEXT_FIELD
      },
      tableConfig: {},
      tableData: [],
      tableHeaders: [
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.icon"),
          type: "STRING",
          cellType: CellType.icon,
          cellConfig: {
            icon: 'icon',
            color: 'iconColor',
            round: false
          },
          value: 'state'
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.timestamp"),
          type: "STRING",
          cellType: CellType.textBackgroundColor,
          cellConfig: {
            round: false,
            outlined: true,
            color: 'timestampColor'
          },
          value: "timestamp",
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.trackTimestamp"),
          type: "STRING",
          cellType: CellType.textBackgroundColor,
          cellConfig: {
            round: false,
            outlined: true,
            color: 'trackTimestampColor'
          },
          value: "trackTimestamp",
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.thingName"),
          type: "STRING",
          value: "thingName",
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.harvestFront"),
          type: "STRING",
          value: "harvestFront",
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.driveUnit"),
          type: "STRING",
          value: "driveUnit",
        },
        {
          align: "right",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.referenceSpeed"),
          type: "STRING",
          value: "referenceSpeed",
        },
        {
          align: "right",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.actualSpeed"),
          type: "STRING",
          value: "actualSpeed",
        },
        {
          align: "right",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.tonsPerHour"),
          type: "STRING",
          value: "tonsPerHour",
        },
        {
          align: "right",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.actualTonsPerHour"),
          type: "STRING",
          value: "actualTonsPerHour",
        },
        {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("efficiency.speedReport.driver"),
          type: "STRING",
          value: "driver",
        },
        
       
      ],
      tableDataKey: "thingName",
      interval: null,
      loadingTable: true,
    }),
    created () {
    },
    async mounted () {
       await this.getSpeedReport();
       await this.getConfig()
       clearInterval(this.interval)
    
      this.interval = setInterval(async()=> {await this.getSpeedReport()}, efficiencyConstants.speedReport.speedReportRefreshInterval)
    },
    computed: {},
    methods: {
        ...mapActions({
          'showSnackbar': 'snackbar/showSnackbar',
          'closeSnackbar': 'snackbar/closeSnackbar'
        }),
        async getSpeedReport (){
          this.loadingTable = true;
            let report= await mapCurrentStateService.getHarvestSpeedReport()
            this.tableData.cleanAndUpdate(report)
          this.loadingTable = false 
           
        },
         /**
         * Para cargar la configuración de la tabla
         */
        async getConfig () {
          const config = await configurationService.get('efficiency-speed-report')
          this.tableConfig = config && config.data ? config.data : {}
        },
        /**
         * Para guardar la configuración de la tabla
         * @param {*} config
         */
        saveConfig (config) {
          configurationService.save('efficiency-speed-report', config)
            .then(() => {
              this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
              this.getConfig()
            }).catch(() => {
              this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
            })
        }
    },
    destroyed() {
      clearInterval(this.interval)
    }
  }