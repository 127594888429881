import SpeedTab from '@/components/efficiency/tabs/speed-tab/SpeedTab.vue'


export default {
    name: 'EfficiencyComponent',
    components: {
     SpeedTab
    },
    props: {
    },
    data: () => ({
      tab: null,
    }),
    created () {
    },
    async mounted () {
    },
    computed: {},
    methods: {
    }
  }
  