
const efficiencyConstants ={
  speedReport: {
    speedReportRefreshInterval: 300000
  }
}



export default efficiencyConstants;
